.popup-container {
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;

  .MuiDialog-paper {
    border-radius: 8px;
    border: 1px solid #eaeaea;
    background: #fff;
    padding: 32px;
    font-family: 'Poppins';

    .MuiDialogContent-root {
      padding: 0;
    }
  }
}

