@import '../../variable.scss';

.main {
  &.empty_page {
    height: 100vh;
  }
}
.content {
  &.empty_row {
    position: relative;
    height: calc(100% - 120px);
    width: 100%;

    .dftButton {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .add-2 {
    width: 90%;
    overflow-x: auto;
    padding-left: 23px;
    margin-left: 81px;
  }
  .upload-style {
    margin-right: 49px;
  }
  .uploading {
    margin-left: 399px;
    margin-top: 223px;
  }
  .uploading-text {
    margin-bottom: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
  }
  .btn_row {
    width: 95%;
  }
}
