@import '../../variable.scss';

.container {
  max-width: 1170px;
  margin: 0 auto;
  height: 100vh;
}

.main-login-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 40px;

  .left-wrapper {
    width: 40%;
    text-align: center;
    box-shadow: -10px 11px 13px rgba(83, 129, 248, 0.24);
    padding: 38px;
    border-radius: 9px;
    h2 {
      margin: 20px 0 20px;
    }
    p {
      margin-bottom: 59px;
    }
  }
  .right-wrapper {
    width: 33.5%;
    margin-left: 190px;
    img {
      width: 100%;
    }
  }
  .login_logo {
    width: 100%;
    text-align: center;
    img {
      width: 180px;
    }
  }
}
.wrap_content {
  width: 100%;
  position: relative;
  top: -75px;
}
.login_field {
  border: 1px solid rgba(3, 1, 76, 0.3);
  border-radius: 9px;
  padding: 23px 46px 23px 60px;
  width: 100%;
}
.login_form {
  width: 100%;
  .input-main-warpper {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    .input-error-message {
      color: rgb(230, 8, 8);
      margin-top: 8px;
      text-align: left;
      padding-left: 5px;
      font-size: small;
    }
    .wrap_field {
      position: relative;
      width: 100%;
      img {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translate(0, -50%);
      }
      img.open_eye {
        position: absolute;
        left: auto;
        right: 20px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
  .login_btn {
    background-color: $blue_color;
    color: #fff;
    width: 100%;
    padding: 23px 8px;
    border-radius: 9px;
    border: 1px solid transparent;
    font-size: 0.9375em;
    font-weight: 600;
    box-shadow: 0px 12px 12px rgba(77, 78, 203, 0.29);
    &:hover {
      border: 1px solid $blue_color;
      background-color: transparent;
      color: $blue_color;
      cursor: pointer;
    }
  }
}
