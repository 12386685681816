@import '../../variable.scss';

.dftButton {
  background-color: $blue_color;
  font-size: 1.5em;
  border-radius: 8.327px;
  color: $white_color;
  border: 1px solid transparent;
  padding: 16px 14px;
  min-width: 405px;
  font-family: 'Open Sans';
  box-shadow: 1px 12px 13px rgba(77, 78, 203, 0.29);
  &:hover {
    box-shadow: none;
    border: 1px solid $blue_color;
    background-color: $white_color;
    color: $blue_color;
    cursor: pointer;
  }
}
