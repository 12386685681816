@import './variable.scss';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  outline: none;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.eot');
  src: url('./assets/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Poppins-Light.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Light.woff') format('woff'), url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.eot');
  src: url('./assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Poppins-Medium.woff2') format('woff2'), url('./assets/fonts/Poppins-Medium.woff') format('woff'),
    url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.eot');
  src: url('./assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Poppins-Regular.woff2') format('woff2'), url('./assets/fonts/Poppins-Regular.woff') format('woff'),
    url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.eot');
  src: url('./assets/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'), url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
    url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype'), url('./assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Light.eot');
  src: url('./assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/OpenSans-Light.woff2') format('woff2'), url('./assets/fonts/OpenSans-Light.woff') format('woff'),
    url('./assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans.eot');
  src: url('./assets/fonts/OpenSans.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/OpenSans.woff2') format('woff2'),
    url('./assets/fonts/OpenSans.woff') format('woff'), url('./assets/fonts/OpenSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Semibold.eot');
  src: url('./assets/fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/OpenSans-Semibold.woff2') format('woff2'), url('./assets/fonts/OpenSans-Semibold.woff') format('woff'),
    url('./assets/fonts/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-Bold.eot');
  src: url('./assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/OpenSans-Bold.woff') format('woff'), url('./assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  width: 100%;
  font-size: 16px;
  font-family: 'open sans', sans-serif;
  color: $primary_color;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

img {
  max-width: 100%;
}

h2 {
  font-size: 2.0625em;
  font-weight: 700;
}

h3 {
  font-size: 1.75em;
  font-family: 'Poppins';
  font-weight: 600;
  color: $black_color;
}

h4 {
  font-size: 1.5em;
  font-family: 'Poppins';
  font-weight: 500;
}

h5 {
  font-size: 1.125em;
  font-family: 'Poppins';
  font-weight: 500;
  text-transform: uppercase;
}

.fnt13 {
  font-size: 0.8125em;
  line-height: normal;
}

.MuiTableCell-head {
  height: 77px;
}
.MuiTableCell-root {
  height: 76px;
}

iframe {
  display: none;
}
