.warp_edit_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .editRow {
    display: flex;
    align-items: center;
    width: 95%;
    .wrap_edit {
      display: flex;
      align-items: center;
      p {
        margin-right: 16px;
        color: #969ba0;
        font-size: 1em;
      }
      .round_btn {
        margin: 0 5px;
      }
      .disabled-button {
        background-color: #e7ddff;
        color: #6d6c70;
      }
    }
  }
}
.search_row {
  &.wdth {
    width: 37.4%;
    margin: 0 26px;
  }
}
.tbl_wrap {
  display: flex;
  width: 68% !important;
  max-height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  table {
    max-height: 90vh;
  }
}

.tbl_wrap1 {
  display: flex;
  width: 34% !important;
  max-height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  table {
    max-height: 90vh;
  }
}

.tbl_wrap2 {
  display: flex;
  width: 34% !important;
  max-height: 100vh;
  align-items: flex-start;
  justify-content: flex-start;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -2%;
  table {
    max-height: 90vh;
  }
}

.main_tbl_wrap {
  display: flex;
  justify-content: space-between;
  .table-name-cell {
    max-width: 150px;
    word-wrap: break-word;
  }
  .table-index-cell {
    font-weight: bold;
  }
}
.selectTbl.MuiTableContainer-root {
  max-height: 100vh;
  width: 30%;
  .makeStyles-table-1 {
    min-width: 300px;
  }
}
.remove-icon {
  cursor: pointer;
}

.divider-line {
  width: 1px;
  min-height: 95%;
  height: auto;
  background: #40189d;
}
.active_play_btn {
  svg {
    path {
      fill: #c0aaf7;
    }
  }
}

.playlist_server_tooltip-wrapper {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 250px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);

  .tooltip-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    .title {
      margin: 0;
      font-family: 'Mulish';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .tooltip-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

