@import '../../variable.scss';

.gray_circle {
  background-color: #6d6c70;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
}
.disabled-button {
  background-color: #e7ddff;
  color: #6d6c70;
}
.wrap_tbl {
  display: flex;
  justify-content: center;
  padding: 0 70px;
  margin-top: 20px;
  .tbl_wraper {
    margin: 0 10px;
  }
  .result_tbl {
    width: 60%;
  }
}
.playlist-scheduler-table {
  table {
    max-height: 90vh;
  }
}
