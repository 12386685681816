@import '../../variable.scss';

.round_btn {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 1em;
  background-color: $sidebar_bg;
  color: #fff;
  padding: 10px 14px;
  border-radius: 43px;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    background-color: $roundBtnHover;
  }
}
