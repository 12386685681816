@import '../../variable.scss';
.filtersMain {
  display: flex;
  justify-content: space-between;
  .upperRow {
    width: 80%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .btn-group {
      display: flex;
      margin-left: 8px;
      column-gap: 8px;
      .not-selected {
        background: #e3e3e3;
        color: #757575;
      }
    }
  }
  .add-btn {
    width: fit-content;
  }
}

.create_playlist {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 20px;
  }
  input {
    background-color: $search_bg;
    padding: 20px 15px;
    width: 100%;
    border: none;
    border-radius: 30px;
    margin-bottom: 20px;
  }
  .add-btn {
    width: 170px;
  }
}

.edit-playlist-main {
  .action_icn {
    // justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    img {
      margin: 0 6px;
      cursor: pointer;
    }
  }
  .tbl_wraper {
    tr {
      td {
        width: auto;
        max-width: 300px;
        word-wrap: break-word;
      }
    }
  }
}

.imageContainerGet {
  width: 60px;
  height: 60px;
  margin-left: 40px;
  border-radius: 6px;
  margin-top: -40px;
}

.thumbNail {
  width: 60px;
  height: 60px;
  margin-left: 40px;
  border-radius: 6px;
  margin-top: -40px;
  background-color: #40189d;
  display: flex;
  justify-content: center;

  p {
    font-size: 32px;
    line-height: 55px;
    color: white;
  }
}
.tag_box {
  width: fit-content;
  height: fit-content;
  padding: 2px 5px;
  border-radius: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: center;
  font-size: 10px;
  color: #000000;
  font-weight: 500;
  line-height: 20px;
}

.icon {
  cursor: pointer;
}

