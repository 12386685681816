@import '../../variable.scss';

.navBar {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 0 40px 0px 70px;
  align-items: center;
  button {
    cursor: pointer;
    border: none;
    outline: none;
  }
}
.wrap_breadcum {
  margin-top: 20px;
  padding: 0 34px;
}

.active_link {
  color: #40189d;
  font-weight: 600;
  font-family: 'Poppins';
}
.bredcum_link {
  font-size: 1em;
  color: $bredcum_text;
}
