@import '../../variable.scss';

.edit-playlist-secondery {
  .tblUpperRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      max-width: 800px;
      width: max-content;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
      padding: 5px;
      outline: none;
      border: none;
      border-radius: 10px;
      box-shadow: 1px 1px #888888;
    }
    .readonly-input {
      background-color: transparent;
      border-radius: none;
      box-shadow: none;
    }
  }
  .tag_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    .tag_item {
      background: #e7ddff;
      padding: 5px 10px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      .tag_circle {
        width: 15px;
        height: 15px;
        margin-left: 0px;
        margin-right: 4px;
      }
      .tag_name {
        font-size: 1em;
        color: black;
      }
      .cross-icon {
        margin-left: 5px;
        cursor: pointer;
        position: relative;
        width: 16px;
        height: 16px;
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: black;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
    .add_tag {
      display: flex;
      align-items: center;
      background: #40189d;
      color: white;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 12px;
      cursor: pointer;
      .tag_text {
        line-height: 16px;
        margin-right: 5px;
      }
    }
  }
  .grp_btn {
    .round_btn {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  .save_row {
    text-align: right;
    margin-top: 15px;
  }

  .table-index-cell {
    font-weight: bold !important;
    padding-left: 30px !important;
  }
  .wrap_action {
    :first-child {
      padding-right: 10px;
    }
    img {
      cursor: pointer;
    }
  }
  .table-height {
    max-height: 90vh;
  }
  .disabled-button {
    background-color: #e7ddff;
    color: #6d6c70;
    &:hover {
      color: white;
    }
  }
}
.play-song-tooltip {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 250px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);

  .tooltip-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;

    .title {
      margin: 0;
      font-family: 'Mulish';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .tooltip-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.tag_search_row {
  width: 100%;
  position: relative;
  .input_field {
    border-radius: 30px;
    background-color: $search_bg;
    font-size: 1em;
    color: $text_dark_grey;
    border: none;
    width: 100%;
    padding: 18px 21px;
  }
  img {
    position: absolute;
    right: 20px;
    top: 16px;
  }
}
.tag_popup {
  height: fit-content !important;
  // max-height: 400px;
}
.tag_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 20px;
  .tag_item {
    display: flex;
    align-items: center;
    // font-size: 1em;
    //* input check box
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 11px;
      margin-bottom: 0px;
    }
    .tag_name {
      font-size: 1em;
      color: $text_dark_grey;
    }
  }
}

.backDrop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: 439px;
    height: 306px;
    background: #ffffff;
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 16px 30px 26px 36px;
    display: flex;
    flex-direction: column;

    .headerText {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #191d23;
    }

    .contentContainer {
      border: 1px dashed #d0d5dd;
      border-radius: 12px;
      width: 100%;
      height: 100px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0px;
      margin-top: 13px;

      .imageContainer {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: center;
        // align-items: center;
      }

      .btnContainer {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .white_btn {
          background: #ffffff;
          box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.15);
          color: #40189d;
        }
      }

      .dragContent {
        width: 273px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #191d23;
        margin-top: 20px;
        margin-bottom: 30px;
      }

      .modal_btn {
        width: 48%;
        max-width: 180px;
        height: 49px;
        background: #40189d;
        border-radius: 46px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

