$primary_color: #03014c;
$blue_color: #4d4ecb;
$sidebar_bg: #40189d;
$roundBtnHover: #4f0af0;
$grey_color: #bfbfbf;
$bg_grey: #f2f2f2;
$white_color: #ffffff;
$black_color: #000000;
$search_bg: #e3e3e3;
$bdr_line: #e7e7e7;
$text_dark_grey: #757575;
$bredcum_text: #969ba0;
$dark_grey: #515151;
$light_green: #1cc700;
$light_pink: #e7ddff;
