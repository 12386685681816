.alert-wrapper-closed {
  display: none;
}

.alert-wrapper-active {
  display: inline-block;
}

.alert-main-wrapper {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 100;
}