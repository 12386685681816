@import '../../variable.scss';

.wrap_tbl {
  &.custmr_tbl {
    padding: 0;
    justify-content: space-between;
    .wrap_tbl_lft {
      width: 40%;
      .tbl_wraper {
        margin: 0;
      }
    }
    .MuiTableContainer-root {
      &.result_playlist {
        width: 56%;
        margin: 0;
      }
    }
  }
}
.child_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_row {
  &.wdth100 {
    width: 100%;
  }
}
.days_row {
  background-color: $white_color;
  border-radius: 30px;
  margin-top: 19px;
  padding: 20px 33px;
  p {
    font-size: 1.125em;
    font-family: 'Poppins';
    font-weight: 600;
  }
  ul {
    li {
      display: inline-block;
      width: auto;
    }
  }
}

.time_add_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .time_row {
    display: flex;
    align-items: center;
    p {
      margin-right: 10px;
    }
    select {
      background-color: $light_pink;
      border: none;
      padding: 10px;
      border-radius: 30px;
      width: 71px;
      margin: 0 6px;
      text-align: center;
      font-size: 1em;
    }
  }
}
.disabled-button {
  background-color: #e7ddff;
  color: #6d6c70;
}
