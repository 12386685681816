@import '../../variable.scss';

.icon_position {
  margin-right: 20px;
}
.middle_icon {
  margin-left: 20px;
}
.indication_circle {
  background-color: #6d6c70;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
  margin-left: 30px;
}
.customer-list-main {
  table {
    max-height: 90vh;
  }
}
.action_icons {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.color_circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  margin-left: 9px;
}
.create_taglist {
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 20px;
  }
  .tag_container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .tag_input {
      background-color: $search_bg;
      padding: 20px 15px;
      width: 80%;
      border: none;
      border-radius: 30px;
      margin-right: 10px;
    }
    .color_input {
      background: transparent;
      appearance: none;
      display: block;
      width: 40px;
      height: 44px;
      // border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
    }
  }
  .add-btn {
    width: 170px;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

