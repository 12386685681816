@import '../../variable.scss';

.logo {
  display: block;
  width: 90px;
  margin: 0 auto;
}

.main {
  display: flex;
  background: $sidebar_bg;
  min-height: 100vh;
}

.heading {
  color: $grey_color;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.125em;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.wrap_column {
  margin: 22px 0;
}
.nav_list {
  padding-left: 22px;
  li {
    position: relative;
    padding: 12px 12px 12px 40px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    svg {
      position: absolute;
      left: 14px;
      width: 19px;
      margin-bottom: 2px;
    }
    a {
      color: $grey_color;
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.125em;
    }

    &.active {
      background-color: $bg_grey;
      color: $black_color;
      font-weight: 600;
      a {
        background-color: $bg_grey;
        color: $black_color;
        font-weight: 600;
      }
      svg path {
        fill: #40189d;
      }
      &:before {
        content: '';
        width: 30px;
        height: 34px;
        background: url('../../assets/corner.png') no-repeat;
        position: absolute;
        right: -4px;
        top: -32px;
        background-size: 29px;
      }
      &:after {
        content: '';
        width: 30px;
        height: 34px;
        background: url('../../assets/bottom_corner.png') no-repeat;
        position: absolute;
        right: -2px;
        bottom: -32px;
        background-size: 28px;
      }
    }
  }
}

.content {
  padding: 40px 36px 40px 33px;
}
