@import '../../variable.scss';

.sidebar {
  width: 258px;
  background-color: $sidebar_bg;
  padding: 28px 0 16px 26px;
  min-height: 100vh;
}
.leftPortion {
  background-color: $bg_grey;
  width: calc(100% - 258px);
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.search_row {
  width: 44.7%;
  position: relative;
  .input_field {
    border-radius: 30px;
    background-color: $search_bg;
    font-size: 1em;
    color: $text_dark_grey;
    border: none;
    width: 100%;
    padding: 18px 41px;
  }
  img {
    position: absolute;
    right: 20px;
    top: 16px;
  }
}

.tbl_wraper {
  border-radius: 30px !important;
  box-shadow: none !important;
  margin-top: 12px;
  max-height: 80vh;
  tr {
    td {
      width: auto;
      max-width: 300px;
      word-wrap: break-word;
    }
  }
}

.MuiTableCell-head {
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5rem;
  font-weight: bolder !important;
}
.table_font {
  font-weight: bold;
}
.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87);
}
img {
  cursor: pointer;
}
