.search_row.search_playlist {
  width: 385px;
}
.not-selected {
  background: #e7ddff;
  color: #6d6c70;
  &:hover {
    background: #e7ddff;
    color: #6d6c70;
  }
}
.wrap_tbl.remove_padding {
  padding: 0 0px;
}
.disabled-button {
  background-color: #e7ddff;
  color: #6d6c70;
}

