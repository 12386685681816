@import '../../variable.scss';
.modal_wrap {
  transform: translate(-50%, -50%);
  background-color: white;
  width: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 20px;
}

.MuiDialogActions-spacing > :not(:first-child) {
  margin-left: 20px !important;
}

.dft_btn {
  font-family: 'Poppins' !important;
  font-weight: 500 !important;
  font-size: 1em;
  background-color: $sidebar_bg !important;
  color: #fff !important;
  padding: 10px 14px !important;
  border-radius: 43px !important;
  border: 1px solid transparent !important;
  cursor: pointer !important;
  &:hover {
    background-color: $roundBtnHover !important;
  }
  &.disable_color {
    background-color: #e7ddff !important;
    color: #6d6c70 !important;
    cursor: initial !important;
  }
  &.ok_btn {
    padding: 10px 25px !important;
  }
}
