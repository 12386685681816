.foreplay-dropdown.MuiFormControl-root {
  min-width: 100%;
  // background-color: #e3e3e3;
  border-radius: 30px;
  padding: 12px 0px;

  .MuiSelect-select {
    &:focus {
      background-color: transparent;
    }
  }

  .MuiInputBase-root {
    &::before,
    &::after {
      // display: none;
    }

    &:focus {
      &::before {
        // display: none;
      }
    }
  }
}

