.icon_position {
  margin-right: 20px;
}
.middle_icon {
  margin-left: 20px;
}
.indication_circle {
  background-color: #6d6c70;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: block;
  margin-left: 30px;
}
.customer-list-main {
  table {
    max-height: 90vh;
  }
}

.pop_up_container {
  width: 193px;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  z-index: 5;
  transform: translateX(10px) translateY(10px);
  transition: transform 0.3 linear;

  li {
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 25px 0 25px;

    p {
      margin-left: 14px;
    }

    &:hover {
      background: #f2f2f2;
      cursor: pointer;
    }
  }
}

.backDropContainer {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.modalBackDrop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockUserModal {
  width: 439px;
  height: 342px;
  background: #ffffff;
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-top: 28px;
    margin-bottom: 20px;
  }

  .label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #191d23;
  }

  .inputContainer {
    margin-top: 50px;
    width: 329px;
  }

  .btn {
    margin-top: 50px;
    width: 200px;
    height: 49px;
    background: #40189d;
    border-radius: 46px;
  }
}

.logsModal {
  width: 633px;
  height: 379px;
  background: #ffffff;
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .row {
    width: 90%;
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #717171;
    padding-top: 11px;
    padding-bottom: 11px;

    .actions {
      flex: 1;
      padding-left: 50px;
    }

    .date {
      width: 120px;
    }
    .time {
      width: 80px;
    }
  }

  .contentContainer {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

