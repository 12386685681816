@import '../../variable.scss';

.btn_row {
  text-align: right;
  margin-top: 0;
}
.input-error-messages {
  color: rgb(230, 8, 8);
  margin-top: 8px;
  text-align: left;
  padding-left: 5px;
  font-size: small;
}
.new_customer_row {
  background-color: $white_color;
  border-radius: 30px;
  padding: 30px 14px;
  margin-top: 13px;
  h5 {
    position: relative;
    margin-bottom: 30px;
    &:before {
      content: '';
      border-top: 1px solid $bdr_line;
      position: absolute;
      left: 90px;
      top: 14px;
      width: calc(100% - 100px);
    }
  }
}
.field_row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  .field_col {
    width: 92%;
    margin-bottom: 30px;
    position: relative;
    &.icn_field {
      input {
        padding: 0 0 14px 60px;
      }
    }
    label {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1em;
      color: $dark_grey;
      display: block;
      margin-bottom: 38px;
    }
    input {
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 1.125em;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #c2c2c2;
      padding-bottom: 14px;
      width: 100%;

      &:focus {
        border-bottom: 2px solid $sidebar_bg;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #bbbbbb;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #bbbbbb;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #bbbbbb;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #bbbbbb;
      }
    }
    img {
      position: absolute;
      left: 21px;
    }
  }
}

.show_password {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 1.125em;
  text-transform: uppercase;
  color: $sidebar_bg;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.input-error-message {
  color: rgb(230, 8, 8);
  margin-top: 8px;
  text-align: left;
  padding-left: 5px;
  font-size: small;
}
